
import { Component, Vue, Watch } from "vue-property-decorator";
import { $layout } from "@/layouts/services/layout.service";
import { turnUrl } from "@/utils";
import * as API_order from "@/api/order";
import * as API_login from '@/api/login'
// import Storage from "@/utils/storage";
import checkVersion from "@/utils/check-version";
@Component({
  name: "app"
})
export default class App extends Vue {
  shutDown = false;
  @Watch("$route", { deep: true, immediate: true })
  async routechange(to, from) {
    const isCardSale = await API_order.getShopExtAuth().then(resStatus => {
      if (resStatus) return resStatus.open === "OPEN";
    });
    // this.PermissionAssignment(
    //   from,
    //   isCardSale,
    //   "order",
    //   "coupon-order-list"
    // );
    // this.PermissionAssignment(
    //   from,
    //   isCardSale,
    //   "finance",
    //   "cancel-after-verification"
    // );
  }

  // PermissionAssignment(from, flag, parentRoute, childrenRoute) {
  //   const routerArr = ["order", "finance"];
  //   // 大平台开关控制菜单路由入口
  //   if (from && routerArr.indexOf(from.path.split("/")[1]) !== -1) {
  //     // 总路由循环
  //     this.$store.getters.permission_routers.forEach(item => {
  //       if (item.path === "/" + parentRoute) {
  //         if (!flag) {
  //           // 关闭菜单
  //           item.children.forEach((value, index) => {
  //             if (value.path === childrenRoute) {
  //               item.children.splice(index, 1);
  //             }
  //           });
  //         }
  //       }
  //     });
  //   }
  // }
  mounted() {
    // checkVersion()
    this.$store.dispatch("address/getAddress");
    const { tokens, fr } = turnUrl(location.href);
    if (tokens) {
      sessionStorage.setItem("session", tokens);
      this.$store.dispatch("loginToken", { token: tokens });
    }
    if (fr) {
      localStorage.setItem('sshl__shop_fr', fr)
    }
    $layout.mode.subscribe(mode => {
      this.$el.parentElement!.className = mode;
    });
    // const user =
    //   Storage.getItem("seller_user") ||
    //   JSON.parse((sessionStorage as any).getItem("from_enter_seller_user"));
    // if (user) {
    //   API_login.getUserRolesPermissions(user.role_id).then(res => {
    //     if (!res.length) {
    //       if (this.$route.path !== "/no-sale-open") {
    //         this.$router.push({ path: "/no-sale-open" });
    //       }
    //     } else {
    //       if (this.$route.path === "/no-sale-open") {
    //         this.$router.push({ path: "/" });
    //       }
    //     }
    //   });
    // }
  }
}
